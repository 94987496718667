import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import axios from "axios"
import { navigate } from "gatsby"
import {
  StyledForm,
  StyledFieldset,
  StyledInput,
  StyledLabel,
  StyledLabelHeader,
  StyledFormElementWrapper,
  ErrorMessage,
} from "../styledComponents/contact"
import { StyledButton } from "../styledComponents/button"

const SignupFreeInformationForm = (data) => {
  const [token, setToken] = useState("") // store token
  const [isSuccessMessage, setIsSuccessMessage] = useState(false) // manage is success message state
  const [messageSent, setMessageSent] = useState(false) // manage sent message state
  const [isFormValid, setIsFormValid] = useState(false)
  const interests = [
      {
        "id": "0c581ee8d4",
        "name": "Free Information - Eliminate pain fast"
      }
    ]
  // Validation
  const validate = values => {
    const errors = {}
    if (!values.firstName) {
      errors.firstName = "Required"
    }
    if (!values.lastName) {
      errors.lastName = "Required"
    }
    if (!values.email) {
      errors.email = "Required"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address"
    }

    Object.keys(errors).length ? setIsFormValid(false) : setIsFormValid(true)
    return errors
  }

  // this effect function authenticates our subcriber user to get a token
  useEffect(() => {
    axios({
      method: "post",
      url: `https://adminwp.karinagrant.co.uk/wp-json/jwt-auth/v1/token`,
      data: {
        username: `contactform`, // provide a user credential with subscriber role
        password: `qR9zizJq^qKbv2#5nopD8ly#`,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        setToken(response.data.token)
      })
      .catch(error => console.error("Error", error))
  }, [])

  // use useFormik hook using object destructuring assignment to extract helpful methods
  const {
    handleChange,
    handleBlur,
    isSubmitting,
    values,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validate,
    onSubmit: (
      {
        firstName,
        lastName,
        email
      },
      { setSubmitting, resetForm }
    ) => {
      setSubmitting(true)
      axios.post("/.netlify/functions/formHandler" , {firstName , lastName ,emailAddress:email.toLowerCase(), interest: interests[0]})
          .then(data => {
            console.log('ERROR ',data)
          })
          .catch(console.log)
      const bodyFormData = new FormData()
      bodyFormData.set("firstName", firstName)
      bodyFormData.set("lastName", lastName)
      bodyFormData.set("email", email)
      // here we sent
      axios({
        method: "post",
        url: `https://adminwp.karinagrant.co.uk/wp-json/contact-form-7/v1/contact-forms/7391/feedback`,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          // actions taken when submission goes OK
          resetForm()
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(true)
          navigate("/thankyou")
        })
        .catch(error => {
          // actions taken when submission goes wrong
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(false)
        })
    },
  })

  useEffect(() => {
    // set timeout 3 seconds to remove error/success message.
    setTimeout(() => {
      // this will reset messageSent and isSuccessMessage state
      setMessageSent(false)
      setIsSuccessMessage(false)
    }, 3000)
    // this effect function will be dispatched when isSuccessMessage or messageSent changes its state
  }, [isSuccessMessage, messageSent])

  return (
    <StyledForm onSubmit={handleSubmit}>
    <StyledFieldset>
      <StyledFormElementWrapper>
        <StyledLabel htmlFor="firstName">
          <StyledLabelHeader
            required
            isEmpty={errors.firstName && touched.firstName}
          >
            First Name
          </StyledLabelHeader>
          <StyledInput
            id="firstName"
            name="firstName"
            type="text"
            onChange={handleChange}
            value={values.FIRSTNAME}
            onBlur={handleBlur}
            placeholder="First name (Required)"
            required
          />
        </StyledLabel>
        {errors.firstName && touched.firstName ? (
          <span className="error-message">{errors.firstName}</span>
        ) : null}
      </StyledFormElementWrapper>
      <StyledFormElementWrapper>
        <StyledLabel htmlFor="lastName">
          <StyledLabelHeader
            required
            isEmpty={errors.lastName && touched.lastName}
          >
            Last Name
          </StyledLabelHeader>
          <StyledInput
            id="lastName"
            name="lastName"
            type="text"
            onChange={handleChange}
            value={values.lastName}
            onBlur={handleBlur}
            placeholder="Last name (Required)"
            required
          />
        </StyledLabel>
        {errors.lastName && touched.lastName ? (
          <span className="error-message">{errors.lastName}</span>
        ) : null}
      </StyledFormElementWrapper>
      <StyledFormElementWrapper>
        <StyledLabel htmlFor="email">
          <StyledLabelHeader
            required
            isEmpty={errors.email && touched.email}
          >
            Email Address
          </StyledLabelHeader>
          <StyledInput
            id="email"
            name="email"
            type="email"
            onChange={handleChange}
            value={values.EMAIL}
            placeholder="email@address.com (Required)"
            onBlur={handleBlur("email")}
            required
          />
        </StyledLabel>
        {errors.email && touched.email ? (
          <span className="error-message">{errors.email}</span>
        ) : null}
      </StyledFormElementWrapper>
      
    </StyledFieldset>
    <StyledButton
      type="submit"
      value="Send Message"
      disabled={isSubmitting || !isFormValid}
    
    >
      Submit
      </StyledButton>
    {messageSent && !isSuccessMessage && (
      <ErrorMessage>
        <p>Something went wrong please try again.</p>
      </ErrorMessage>
    )}
  </StyledForm>
  )
}

export default SignupFreeInformationForm
