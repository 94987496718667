import React, { useState } from "react"
import parse from "html-react-parser"
import styled from "styled-components"
import { ModalProvider, BaseModalBackground } from "styled-react-modal"
import { useSwipeable } from "react-swipeable"

import {
  CarouselSection,
  CarouselWrapper,
  CarouselList,
  CarouselListItem,
  CarouselArrows,
  CarouselArrowItem,
  CarouselListDot,
  CarouselListDotItem,
  
} from "../styledComponents/heroItems"
import heroPromos from "../fixtures/homepageHero"
import SignupFreeInformationForm from "../components/formFreeInformation"
import { StyledButton, StyledLinkButton, StyledCloseModalButton } from "../styledComponents/button"
import Icon from "../components/icon"
import { StyledModal } from "../styledComponents/modal"
import { StaticImage } from "gatsby-plugin-image"
function FancyModalButton(data) {
  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);

  function toggleModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);

  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }

  return (
    <>
      <StyledButton onClick={toggleModal} className="learn-more" >Request more information</StyledButton>
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
        className="order-modal more-info-modal"
      >
       <StyledCloseModalButton onClick={toggleModal}>
          <Icon name="cross" />
        </StyledCloseModalButton>
        
        <h2>Get more information</h2>
        <p>Learn about how Quantum-Touch can help you or a loved one!</p>
        <SignupFreeInformationForm />
      </StyledModal>
    </>
  )
}

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
  z-index: 100;
`;

const HeroCarousel = ({ data, isLander }) => {
  const [currentQuoteIndex, setCurrentQuote] = useState(0)
  const totalQuotes = heroPromos.length
  const handlers = useSwipeable({
    onSwipedLeft: () => swipe(currentQuoteIndex, "left"),
    onSwipedRight: () => swipe(currentQuoteIndex, "right"),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    trackTouch: true,
  })

  const swipe = (currentQuoteIndex, direction) => {
    currentQuoteIndex = parseInt(currentQuoteIndex)
    if (currentQuoteIndex === 0) {
      return direction === "left"
        ? setCurrentQuote(currentQuoteIndex + 1)
        : setCurrentQuote(0)
    }

    if (currentQuoteIndex + 1 === heroPromos.length) {
      return direction === "left"
        ? setCurrentQuote(currentQuoteIndex)
        : setCurrentQuote(currentQuoteIndex - 1)
    }

    if (currentQuoteIndex > 0) {
      return direction === "left"
        ? setCurrentQuote(currentQuoteIndex + 1)
        : setCurrentQuote(currentQuoteIndex - 1)
    }
  }

  return (
    <CarouselSection>
      <CarouselWrapper>
        <CarouselList
          {...handlers}
          totalQuotes={parseInt(heroPromos.length)}
          index={parseInt(currentQuoteIndex)}
        >
          {heroPromos.map((hero, index) => {
            return (
              <CarouselListItem isInFocus={parseInt(currentQuoteIndex) === index} key={`${index}-${hero.title}`}>
                <StaticImage 
                  src="https://karinagrant.s3.eu-west-1.amazonaws.com/images/karina-grant-corporate-wellness.jpg"
                  alt=""
                  />
                 <h1>{hero.title}</h1>
                 {parse(hero.excerpt)}
                 {hero.showModalButton  &&
                  <ModalProvider backgroundComponent={FadingBackground}>
                    <FancyModalButton  />
                  </ModalProvider>
                }
                {hero.buttonLink &&
                  <StyledLinkButton inverse="true" className="learn-more" to={hero.buttonLink}>{hero.buttonText}</StyledLinkButton>
                }
              </CarouselListItem>
            )
          })}
        </CarouselList>
          {totalQuotes > '1' && (
            <CarouselListDot>
              {heroPromos.map((quote, index) => {
                return (
                  <CarouselListDotItem>
                    <label htmlFor={quote.id}>
                    <input
                      type="radio"
                      id={quote.id}
                      checked={parseInt(currentQuoteIndex) === index}
                      name="quote"
                      value={index}
                      onChange={e => setCurrentQuote(e.target.value)}
                    />
                    </label> 
                  </CarouselListDotItem>
                )
              })}
            </CarouselListDot>
          )}

        {totalQuotes > '1' && (
          <CarouselArrows>
            <CarouselArrowItem>
              <button onClick={() => swipe(currentQuoteIndex, "right")} disabled={parseInt(currentQuoteIndex) === 0} >
                <svg
                  width="82"
                  height="82"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <circle
                      cx="41"
                      cy="41"
                      r="41"
                      transform="rotate(-180 41 41)"
                      fill="none"
                    />
                    <path
                      d="M44.53 28c.368 0 .735.147 1.03.44a1.462 1.462 0 010 2.093L35.6 40.482l9.958 9.985a1.462 1.462 0 010 2.093 1.465 1.465 0 01-2.094 0L32.44 41.546A1.45 1.45 0 0132 40.518c0-.404.147-.77.44-1.028l11.025-11.013c.33-.33.698-.477 1.065-.477z"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </button>
            </CarouselArrowItem>
            <CarouselArrowItem>
              <button onClick={() => swipe(currentQuoteIndex, "left")} disabled={parseInt(currentQuoteIndex + 1) === totalQuotes}  >
                <svg
                  width="82"
                  height="82"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <circle
                      cx="41"
                      cy="41"
                      r="41"
                      transform="rotate(-180 41 41)"
                      fill="none"
                    />
                    <path
                      d="M37.47 53c-.368 0-.735-.147-1.03-.44a1.462 1.462 0 010-2.093l9.959-9.949-9.958-9.985a1.462 1.462 0 010-2.093 1.465 1.465 0 012.094 0L49.56 39.454c.294.293.441.66.441 1.028 0 .404-.147.77-.44 1.028L38.534 52.523c-.33.33-.698.477-1.065.477z"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </button>
            </CarouselArrowItem>
          </CarouselArrows>
        )}
      </CarouselWrapper>
    </CarouselSection>
  )
}

export default HeroCarousel