const fixture = [
    {

        excerpt: "<p>I teach <strong>Quantum-Touch Energy Healing</strong>, <strong>Permission to Reset™ Anxiety techniques</strong>, <strong>Mindfulness</strong> and <strong>Corporate Wellbeing programs</strong>.</p>",
        image: "https://karinagrant.s3.eu-west-1.amazonaws.com/images/karina-grant-corporate-wellness.jpg",
        type: "homepage",
        id: "1",
    }
    // ,{
    //     title: "Permissioning & Transformation™",
    //     excerpt: "<p>A set of techniques that are highly effective at overcoming <strong>anxiety</strong>, <strong>stress</strong>, <strong>insomnia</strong> & <strong>regulating the nervous system</strong>.</p>",
    //     image: "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/wp-content/uploads/2019/09/bloom-blooming-blossom-462118-1320x880.jpg",
    //     type: "homepage",
    //     id: "2",
    //     //showModalButton: true
    //     buttonText: "Read more",
    //     buttonLink: "/permissioning-and-transformation#info"
    // },
    // {
    //     title: "Quantum-Touch",
    //     excerpt: "<p>Energy healing based on scientific principles using specific breathing techniques & body awareness exercises to reduce/eliminate pain.</p>",
    //     excerpt: "<p>Learn a simple, yet powerful technique to relieve pain naturally.</p><ul><li>Inexpensive</li><li>Effective</li><li>Easy to learn</li></ul>",
    //     excerpt: "<p>Learn simple, yet powerful techniques based on scientific principles to <strong>relieve pain naturally</strong>.</p>",
    //     image: "/images/hands-heart-world.jpg",
    //     image: "/images/quantum-touch-energy-healing.jpg",
    //     type: "homepage",
    //     id: "3",
    //     //showModalButton: true
    //     buttonText: "Read more",
    //     buttonLink: "/quantum-touch/what-is-quantum-touch-energy-healing"
    // },
  ]
  
  export default fixture