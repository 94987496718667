import styled from "styled-components"
import breakpoints from "./breakpoints"
import { bold18, bold32, bold28, bold16, bold20, bold40, bold80, bold72, bold48 } from "./typography"

const card = { width: "100vw", margin: 0, height: "100vh" }
const cardSm = { width: "100vw", margin: 0, height: "100vh" }
const cardMd = { width: "100vw", margin: 0, height: "100vh" }
const cardLg = { width: "100vw", margin: 0, height: "100vh" }

const marginLeft = index => {
  if (index === 0) {
    return `
      margin-left: calc((100vw - (${card.width} + (2 * ${card.margin}px))) / 2);

      @media screen and  (min-width: ${breakpoints.sm}px){
        margin-left: calc((100vw - (${cardSm.width} + (2 * ${cardSm.margin}px))) / 2);
      }
      
      @media screen and  (min-width: ${breakpoints.md}px){
        margin-left: calc((100vw - (${cardMd.width} + (2 * ${cardMd.margin}px))) / 2);
      }

      @media screen and  (min-width: ${breakpoints.l}px){
        margin-left: calc((100vw - (${cardLg.width} + (2 * ${cardLg.margin}px))) / 2);
      }
    `
  } else {
    return `

    margin-left: calc(((100vw - (${card.width} + (2 * ${card.margin}px))) / 2) - (${index} * (${card.width} + (2 * ${card.margin}px))));

    @media screen and  (min-width: ${breakpoints.sm}px){
      margin-left: calc(((100vw - (${cardSm.width} + (2 * ${cardSm.margin}px))) / 2) - (${index} * (${cardSm.width} + (2 * ${cardSm.margin}px))));
    }
    
    @media screen and  (min-width: ${breakpoints.md}px){
      margin-left: calc(((100vw - (${cardMd.width} + (2 * ${cardMd.margin}px))) / 2) - (${index} * (${cardMd.width} + (2 * ${cardMd.margin}px))));
    }

    @media screen and  (min-width: ${breakpoints.l}px){
      margin-left: calc(((100vw - (${cardLg.width} + (2 * ${cardLg.margin}px))) / 2) - (${index} * (${cardLg.width} + (2 * ${cardLg.margin}px))));
    }
    `
  }
}

const CarouselSection = styled.section`
  display: block;
  max-width: 100%;
  background-color: var(--light-blue-lightest);
  padding-bottom: 1.6rem;
  margin-bottom: 2.4rem;
  grid-column: 1 / 6;

  @media screen and (min-width: ${breakpoints.sm}px) {
    padding: 0 0 2.4rem;
    grid-column: 1 / 5;
  }
  
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 1 / 10;
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    padding: 0 0 3.2rem;
    margin: 0rem auto;
  }
`

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
`
const CarouselList = styled.ul`
  ${props => marginLeft(props.index, props.totalQuotes)};
  transition: all 0.5s ease-in-out;
  ${props =>
    props.totalQuotes &&
    `width:calc(${props.totalQuotes}00vw + (16px*${props.totalQuotes * 2}));
      @media screen and (min-width: ${breakpoints.sm}px) {
       width:calc(${props.totalQuotes}00vw)
      }
      @media screen and  (min-width: 1304px){
        width:calc(${props.totalQuotes}*100vw +  26.5vw + (${props.totalQuotes} * 75px));
      }
    `};
  list-style-type: none;
  display: flex;
  padding: 0;
  margin-top: 0;
  align-items: center;
  min-height: calc(51rem - (25px + 72px));
`

const CarouselListItem = styled.li`
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position:  50% 0;
  background-color: #953491;
  width: 100%;
  height: calc(100vh - 58px);
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  padding: 3rem;
  width: ${card.width};
  margin: 0 ${card.margin}px;
  opacity: ${props => (props.isInFocus ? `1` : `0.08`)};
  transition: opacity 0.35s ease-in-out;
  min-height: calc(51rem - (25px + 72px));
  @media screen and (min-width: ${breakpoints.sm}px) {
    width: ${cardSm.width};
    margin: 0 ${cardSm.margin}px;
    height: calc(100vh - 74px);
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    width: ${cardMd.width};
    margin: 0 ${cardMd.margin}px;
    height: calc(100vh - 62px);
  }

  @media screen and (min-width: ${breakpoints.l}px) {
    width: ${cardLg.width};
    margin: 0 ${cardLg.margin}px;
    height: calc(100vh - 94px);
  }

  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:after {
      content: " ";
      display: block;
      width: 100vw;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      position: absolute;
      background: rgb(125,23,154);background: radial-gradient(circle, rgba(125,23,154,1) 0%, rgba(255,255,255,0) 100%, rgba(255,255,255,1) 100%);;
  }
  }

  &.about-karina {
    p {

      @media screen and (min-width: ${breakpoints.md}px) {
        max-width: 50vw;
      }
      @media screen and (min-width: ${breakpoints.l}px) {
        font-weight: 700;
        font-size: 3rem;
        line-height: 4rem;
      }
    }
  }

  &:before {
      content: " ";
      display: block;
      width: 100vw;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      position: absolute;
      background: rgb(125,23,154);background: radial-gradient(circle, rgba(125,23,154,1) 0%, rgba(255,255,255,0) 100%, rgba(255,255,255,1) 100%);;
  }

  h1 {
    text-align: center;
    color: var(--white);
    margin: 0;
    ${bold40};
    position: relative;
    /* ${props => props.isGift && 'margin-bottom: 4rem'}; */
    
    @media screen and (min-width: ${breakpoints.sm}px) {
        margin-bottom: 2.4rem;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        ${bold72};
        max-width: 70vw;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
        ${bold80};
    }
  }

  p {
    text-align: center;
    color: var(--white);
    margin: 2.4rem 0 0;
    position: relative;
    ${bold20};
    @media screen and (min-width: ${breakpoints.sm}px) {
      max-width: 50vw;
      margin: 0;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      margin: 2.4rem 4rem 0;
      max-width: 40vw;
      ${bold32};
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      font-weight: 500;
      font-size: 4.6rem;
      line-height: 5.2rem;
    }

    span {
        text-transform: uppercase;
        display: block;
        font-size: 1.8rem;
    }
  }

  ul{
    color: var(--white);
    list-style-position: inside;
    list-style-type: none;
    padding: 0;
    text-align: center;
    ${bold18}
    margin-bottom: 1.6rem;
    z-index: 1;
    @media screen and (min-width: ${breakpoints.sm}px) {
        margin-bottom: 2.4rem;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        margin-bottom: 4rem;
        margin-top: 4rem;
    }

    li {
      ${bold16}
      @media screen and (min-width: ${breakpoints.sm}px) {
        margin: 0 2rem;
        display: inline;
        list-style-type: circle;
        list-style-position: outside;
        margin: 0 0.8rem;
      }
      @media screen and (min-width: ${breakpoints.md}px) {
          ${bold28}
      }
    }
  }

  button {
    z-index: 1;
  }

  a.learn-more {
    background: #00000036;
    color: var(--white);
    ${bold20}
    border-radius: 30px;
    z-index: 1;
    border: 2px solid var(--white);
    margin-top: 3.2rem;
    @media screen and (min-width: ${breakpoints.sm}px) {
        width: 22.5rem;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        ${bold28}
        margin-top: 3.2rem;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        ${bold28}
        margin-top: 4.8rem;
    }
  }
  
`

const CarouselArrows = styled.ul`
  display: none;
  @media screen and (min-width: ${breakpoints.sm}px) {
    display: flex;
    justify-content: space-between;
    z-index: 1;
    align-items: center;
    position: absolute;
    top: calc((${cardSm.height} / 2) - 72px);
    left: 2.4rem;
    right: 2.4rem;
    width: calc(${cardSm.width} - 82px);
    margin: 0 auto;
    padding: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    top: calc((${cardMd.height} / 2) - 41px);
    left: 2.4rem;
    /* right: 2.4rem; */
    width: calc(${cardMd.width} - 48px);
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    top: calc((${cardLg.height} / 2) - 41px);
    left: 4rem;
    width: calc(${cardLg.width} - 82px);
  }
`
const CarouselArrowItem = styled.li`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  z-index: 2;

  button {
    background: #ffffff2b;
    border-radius: 50%;
    border: 1px solid #fff;
    outline: 0;
    position: relative;
    padding: 0;
    cursor: pointer;

    &:disabled {
      cursor: initial;
      border: 1px solid #dedede;
      opacity: 0.3;
      svg {
        opacity: 0.3;
        transition: all 0.8s ease-in-out;
      }
    }
  }
`

const CarouselListDot = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  z-index: 1;
  position: absolute;
  bottom: 2rem;
  width: 100%;
  
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin-top: 2rem;
  }
`

const CarouselListDotItem = styled.li`
  margin: 0 3px;
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 0;
  }

  input {
    width: 1.7rem;
    height: 1.7rem;
    appearance: none;
    border-radius: 50%;
    border: 0.3rem solid var(--white);
    display: block;
    margin: 0.425rem;
    outline: none;
    cursor: pointer;

    &:checked {
      border: 0.3rem solid var(--white);
      background: var(--white);
    }
  }
`
export {
  CarouselSection,
  CarouselWrapper,
  CarouselList,
  CarouselListItem,
  CarouselArrows,
  CarouselArrowItem,
  CarouselListDot,
  CarouselListDotItem,
}
