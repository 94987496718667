import React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import parse from "html-react-parser"
import LayoutNoStripe from "../components/layoutNoStripe"
import SEO from "../components/seo"
import {
  Section,
  SectionHeading,
  SectionHeaderLedeDiv,
  SectionCorporateLogoList,
} from "../styledComponents/section"
import SignupNewsletterForm  from "../components/formNewsletterSignup"
import { StyledSignUpForm } from "../styledComponents/signUpForm"
import Testimonials from "../components/testimonials"
import { StyledAboutKarina } from "../styledComponents/aboutKarina"
import EventList from "../components/EventList"
import {
  StyledViewAllEventsButtonWrapper
} from "../styledComponents/eventArchive"
import { StyledLinkButton } from "../styledComponents/button"
import HeroCarousel from "../components/heroCarousel"
import { CoroporateLogoList } from "../components/Corporate/LogoList"
import { StyledGalleryWrapper } from "../styledComponents/galleryoverrides"
import ProductList from "../components/ProductList"


const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)

const Home = ({ data, location, createCookie }) => {
  
  let posts = data.allWpPost.nodes
  const orderPostsByPinned = () =>
    posts.sort((a,b) => {
      var postA = a
      var postB = b
      if (postA.isSticky === true) {
        return new Date(postA.date) - new Date(postB.date);
      }
      if (postA === false) {
        return 1
      }

      //posts are the same
      return 0
    })
  posts = orderPostsByPinned()
  
  let products = data.allWpProduct.edges
  const image = {
    imageData: data.aboutKarina.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: data.aboutKarina.featuredImage?.node?.alt || ``,
  }

    return (
      <LayoutNoStripe location={location} isHomePage>
        <SEO 
          title="Energy Healing &amp; Chakra Balancing" 
          description="Energy Healing in London Teacher &amp; Therapist Karina Grant offers Quantum-Touch Energy Healing, Permissioning &amp; Transformation™, Distance Healing, Mindfulness Meditations. Boost your immunity and live a healthy, joyous and balanced life."
          image={image.metaImage}
          url=""
        />
        <HeroCarousel /> 
        
          <Section background='var(--white)'>
            <Heading level={1}>Audio Programs &amp; Meditations</Heading>
            <ProductList posts={products} />
            <StyledViewAllEventsButtonWrapper><StyledLinkButton to="/shop">View all</StyledLinkButton></StyledViewAllEventsButtonWrapper>
          </Section>

        <StyledSignUpForm>
          <SignupNewsletterForm createCookie={createCookie}/>
        </StyledSignUpForm>

        <Testimonials
          data={data} 
          showDots={true}
          showTestimonialsSectionHeader={true} 
          showMoreTestimonialsButton={true} 
          showMoreTestimonialsButtonLink="/testimonials" 
          showMoreTestimonialsButtonText="Read more testimonials"
        />

        <SectionCorporateLogoList background='var(--grey-lightest)'>
          <Heading level={1} align="center">Corporate Wellness</Heading>
          <SectionHeaderLedeDiv align="center"><p>Karina delivers bespoke corporate wellness programs.</p></SectionHeaderLedeDiv>
            <StyledGalleryWrapper>
              <CoroporateLogoList />
            </StyledGalleryWrapper>
          <StyledViewAllEventsButtonWrapper><StyledLinkButton to="/corporate-wellness">Find out more...</StyledLinkButton></StyledViewAllEventsButtonWrapper>
        </SectionCorporateLogoList>
        <StyledAboutKarina>
        {image && image.imageData && (
          <figure>
             <StaticImage
                  src="../images/profile/karina-grant.jpg"
                  alt="Headshot of Karina Grant"
                  className="karina-headshot"
                />
          </figure>
        )}
          <div>
            <h2>Karina Grant</h2>
            {parse(data.aboutKarina.aboutKarina.aboutKarina)}
            <StyledViewAllEventsButtonWrapper><StyledLinkButton to="/about">Read more about Karina</StyledLinkButton></StyledViewAllEventsButtonWrapper>
          </div>
        </StyledAboutKarina>
        

      </LayoutNoStripe>
    )
}

export default Home

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    wpPage(slug: { eq: "homepage" }) {
      title
      slug
      content
      testimonials {
        blockquote {
          citeLocation
          citationName
          quote
        }
        testimonialHeading
        testimonialLede
      }
    }

    aboutKarina: wpPage(slug: { eq: "about" }) {
      aboutKarina {
        aboutKarina
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
              fixed {
                src
              }
            }
          }
        }
      }
    }
      
    allWpPost(sort: { fields: [date], order: DESC } limit: 5) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        isSticky
        categories {
          nodes {
            slug
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 975
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }

    allWpProduct(sort: {order: ASC, fields: menuOrder}, limit: 3, filter: {productsTag: {nodes: {elemMatch: {products: {nodes: {elemMatch: {productsTag: {nodes: {elemMatch: {name: {ne: "Book"}}}}, productData: {pricefull: {ne: 0}}}}}}}}}) {
      edges {
        node {
          title
          uri
          id
          excerpt
          productsTag {
            nodes {
              name
            }
          }
          productData {
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            hidesale
            hideonarchive
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
}
`
